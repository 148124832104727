import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/IndexView.vue'),
    children: [{
      path: '/',
      component: () => import('../views/index/HomeView.vue')
    },
    {
      path: 'bookInfo',
      component: () => import('../views/BookInfoView.vue')
    },
    {
      path: 'RankList/:id',
      component: () => import('../views/RankListView.vue')
    },

    {
      path: 'searchList',
      component: () => import('../views/searchListView.vue')
    },
    {
      path: 'NewAllBook',
      component: () => import('../views/NewAllBookView.vue')
    },
    {
      path: 'PersonalInfo',
      component: () => import('../views/PersonalInfo.vue'),
      children: [
        {
          path: 'bookcase',
          component: () => import('../views/BookcaseView.vue'),
        },
        {
          path: 'BookcaseInfo/:bookCaseId',
          component: () => import('../views/BookcaseInfoView.vue')
        }, {
          path: 'plan',
          component: () => import('../views/PlanView.vue'),
        }, {
          path: 'note',
          component: () => import('../views/noteView.vue'),
        }, {
          path: 'ReadAlready',
          component: () => import('../views/ReadAlreadyView.vue'),
        }, {
          path: 'Statistics',
          component: () => import('../views/StatisticsView.vue'),
        }, {
          path: 'myInfo',
          component: () => import('../views/myInfoView.vue'),
        },
        {
          path: 'allBook',
          component: () => import('../views/AllBookView.vue')
        },
      ]
    }]
  },
  {
    path: '/AppDownload',
    name: 'AppDownload',
    component: () => import('../views/AppDownloadView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
