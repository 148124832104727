import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: "" || localStorage.getItem('userInfo'),
    spinning: false,
  },
  getters: {
  },
  mutations: {
    updateUserInfo(state:any, userInfo:any) {
      state.userInfo = JSON.stringify(userInfo)
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    updateSpinning(state:any, num:any) {
      state.spinning = num !== 0;
    },
  },
  actions: {
  },
  modules: {
  }
})

